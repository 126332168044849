import { render, staticRenderFns } from "./projectchange.vue?vue&type=template&id=13516bab&scoped=true&"
import script from "./projectchange.vue?vue&type=script&lang=js&"
export * from "./projectchange.vue?vue&type=script&lang=js&"
import style0 from "./projectchange.vue?vue&type=style&index=0&id=13516bab&prod&lang=scss&scoped=scoped&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13516bab",
  null
  
)

export default component.exports