<template>
    <el-dialog :title="!dataForm.recNo ? '工程信息变更登记' : '工程信息变更修改'" :close-on-click-modal="false" :visible.sync="visible"
        width="550px">
        <el-form ref="dataForm" :model="dataForm" :dataRule="dataRule" :inline="true" label-width="110px"
            label-position="left" class="border-form">
            <el-row class="set_border">
                <el-col :span="24" class="col-set_border">
                    <el-form-item label="变更项目" prop="changeProject" class="item">
                        <el-input class="selItemInput1" type="input" v-model="dataForm.changeProject"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row class="set_border">
                <el-col :span="24" class="col-set_border">
                    <el-form-item label="变更内容" prop="changeContext" class="item">
                        <el-input class="selItemInput1" type="textarea" rows="2"
                            v-model="dataForm.changeContext"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row class="set_border">
                <el-col :span="24" class="col-set_border">
                    <el-form-item label="变更时间" class="item">
                        <el-date-picker class="selItemInput1" value-format="yyyy-MM-dd" v-model="dataForm.changeTime"
                            type="date" style="width:300px">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row class="set_border">
                <el-col :span="24" class="col-set_border">
                    <!-- <el-form-item label="变更工作量" class="fileItem">
                    </el-form-item> -->
                    <div class="fileItem">变更工作量</div>
                </el-col>
            </el-row>
            <el-row class="set_border">
                <el-col :span="24" class="col-set_border">
                    <el-form-item label="人（人/天）" class="item">
                        <el-input class="selItemInput1" type="input" v-model="dataForm.changePerson"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row class="set_border">
                <el-col :span="24" class="col-set_border">
                    <el-form-item label="设备（台/天）" class="item">
                        <el-input class="selItemInput1" type="input" v-model="dataForm.changeDevice"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row class="set_border">
                <el-col :span="24" class="col-set_border">
                    <el-form-item label="材料" class="item">
                        <el-input class="selItemInput1" type="input" v-model="dataForm.changeMaterial"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row class="formBtnGroup">
                <el-button v-preventReClick class="btn" type="primary" size="small" @click="dataFormSubmit()">保存</el-button>
            </el-row>
        </el-form>
    </el-dialog>
</template>

<script>
import $common from "@/utils/common.js"
export default {
    name: "workloginfo-add-or-update",
    data() {
        return {
            visible: true,
            dataForm: {
                workNo: $common.getItem("workNo"),
                recNo: '',
                changeProject: '',
                changeContext: "",
                changeTime: '',
                changePerson: '',
                changeDevice: '',
                changeMaterial: '',
            },
            dataRule: {
                changeProject: [
                    { required: true, message: "请输入变更项目", trigger: "blur" }
                ],
                changeContext: [
                    { required: true, message: "请输入变更项目", trigger: "blur" }
                ],
            }
        }
    },
    components: {

    },
    methods: {
        getCurrentTime() {
            //获取当前时间并打印
            var _this = this;
            let yy = new Date().getFullYear();
            let mm = (new Date().getMonth() + 1) < 10 ? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1);
            let dd = (new Date().getDate()) < 10 ? '0' + (new Date().getDate()) : (new Date().getDate());
            let hh = new Date().getHours();
            let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
            let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds();
            return yy + '-' + mm + '-' + dd;
        },
        init(id) {
            this.dataForm = {
                workNo: $common.getItem("workNo"),
                recNo: '',
                changeProject: '',
                changeContext: "",
                changeTime: '',
                changePerson: '',
                changeDevice: '',
                changeMaterial: '',
            }
            this.dataForm.changeTime = this.getCurrentTime();
            this.dataForm.recNo = id ? id : 0;
            if (id) {
                this.getDetail();
            }
            this.$nextTick(() => {
                this.visible = true;
            })
        },
        getDetail() {
            this.$http({
                url: this.$store.state.httpUrl + "/business/projectchange/info/" + this.dataForm.recNo,
                method: "get",
                params: {},
            }).then(({
                data
            }) => {
                if (data && data.resultCode === 200) {
                    this.dataForm = data.body;
                }
            });
        },
        dataFormSubmit() {
            this.$refs["dataForm"].validate(valid => {
                if (valid) {
                    let _url = "/business/projectchange/save";
                    if (this.dataForm.recNo && this.dataForm.recNo != 0) {
                        _url = "/business/projectchange/update";
                    }
                    this.$http({
                        url: this.$store.state.httpUrl + _url,
                        method: "post",
                        data: this.$http.adornData(this.dataForm)
                    }).then(({
                        data
                    }) => {
                        if (data && data.resultCode === 200) {
                            this.$message({
                                message: "操作成功",
                                type: "success",
                                duration: 1500,
                                onClose: () => {
                                    this.visible = false;
                                    this.$parent.getDataList();
                                }
                            });
                        }
                    });
                }
            });
        },
    }
}
</script>

<style scoped="scoped">
::v-deep .el-form-item__label {
    color: #00428E !important;
    margin-right: 10px !important;
    ;
    font-weight: bold !important;
    ;
    background-color: #E1F1FF;
    text-align: right !important;
}

/* .fileItem::v-deep .el-form-item__label {
    text-align: left;
    color: #00428e !important;
    width: 390px !important;
    background-color: rgb(211, 230, 248);
} */

.fileItem {
    padding-top: 12px;
    padding-left: 30px;
    text-align: left;
    font-weight: bold !important;
    ;
    font-size: 14px;
    color: #00428e !important;
    width: 489px !important;
    height: 40px;
    background-color: #E1F1FF;
    /* margin-bottom: 20px; */
}

.item::v-deep .el-form-item__label {
    color: #00428E !important;
    margin-right: 10px !important;
    font-weight: bold !important;
    background-color: #E1F1FF;
    white-space: nowrap !important;
    border-right: solid #dadada 1px;
}

.border-form .el-row.set_border {
    border-bottom: solid #dadada 1px;
    border-left: solid #dadada 1px;
    border-right: solid #dadada 1px;
}

.border-form .el-row.set_border:first-child {
    border-top: solid #dadada 1px;
}

::v-deep .el-form-item {
    display: flex !important;
    align-items: stretch !important;
    margin-bottom: 0px !important;
}

.selInput {
    width: 405px !important;
}

.selItemInput1 {
    width: 340px !important;
}
</style>
