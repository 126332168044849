<template>
	<!-- 工程变更信息 -->
	<div class="mod-config">
		<el-card body-style="padding-bottom: 0;" style="margin-top: -5px;">
			<div class="title">工程变更信息</div>
			<div class="line"></div>
			<el-row style="margin-bottom: 5px;">
				<el-col :span="pageSpan">
					<el-button v-preventReClick class="btn" type="primary" size="small"
						@click="addOrUpdateHandle()">新增</el-button>
					<el-button v-preventReClick class="btn" type="primary" size="small" :disabled="disabledBatchDelete"
						@click="deleteBatch()">删除</el-button>
					<el-button v-preventReClick class="btn" type="primary" size="small"
						@click="downloadHandle()">导出</el-button>
				</el-col>
			</el-row>
			<div class="line"></div>
			<el-form ref="form" :model="form" inline label-width="80px" label-position="left">
				<el-row style="background-color: rgb(243, 247, 247);margin-top: -10px;margin-left: -20px;height: 45px;width: 103%;">
					<el-col :span="17" style="margin-left:20px">
						<el-form-item label="变更项目" class="selFormItem">
							<el-input class="selSearchInput" v-model="changeProject" placeholder="请填写"></el-input>
						</el-form-item>
						<el-form-item label="变更内容" class="selFormItem">
							<el-input class="selSearchInput" v-model="changeContext" placeholder="请填写"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="6" class="selBtnGroup" style="margin-top:5px">
						<el-button v-preventReClick class="btn" type="primary" size="small"
							@click="selGetDataList()">查询</el-button>
						<el-button v-preventReClick class="rebtn" size="small" @click="resetFormData()">重置</el-button>
					</el-col>
				</el-row>
			</el-form>
		</el-card>
		<div>
			<el-card style="margin-top: -25px; height: 550px;" body-style="height:510px; padding: 0;">
				<el-row style="height: 100%; margin-bottom: 10px;">
					<el-col :span="24" style="height: 100%;">
						<div class="box">
							<el-table height="490" :row-class-name="tableRowClassName" :row-style="selectRowBackground"
								class="dataListTable" :data="dataList" header-align="center" border
								style="width: 100%;margin-bottom: 20px;" header-cell-class-name="stepPointTHeader"
								:cell-style="{ padding: '0px' }" @selection-change="handleSelectionChange">
								<el-table-column type="selection" width="60" align="center"></el-table-column>
								<el-table-column type="index" label="NO." width="70" align="center">
									<template slot-scope="scope">{{ (pageIndex2 - 1) * pageSize + scope.$index + 1
									}}</template>
								</el-table-column>
								<el-table-column prop="changeProject" label="变更项目" width="300" align="left"
									header-align="center">
								</el-table-column>
								<el-table-column prop="changeContext" label="变更内容" min-width="300" align="left"
									header-align="center">
								</el-table-column>
								<el-table-column prop="changeTime" label="变更时间" width="150" align="center">
								</el-table-column>
								<el-table-column label="变更工作量" align="left" header-align="center" width="390">
									<el-table-column prop="changePerson" label="人（人/天）" width="130" align="left" header-align="center">
									</el-table-column>
									<el-table-column prop="changeDevice" label="设备（台/天）" width="130" align="left" header-align="center">
									</el-table-column>
									<el-table-column prop="changeMaterial" label="材料" width="130" align="left" header-align="center">
									</el-table-column>
								</el-table-column>
								<el-table-column label="操作" width="100" fixed="right" align="center">
									<template slot-scope="scope">
										<el-button v-preventReClick type="text" size="small"
											@click="addOrUpdateHandle(scope.row.recNo)">修改</el-button>
									</template>
								</el-table-column>
							</el-table>
						</div>
						<el-pagination class="my-pagination" @size-change="sizeChangeHandle"
							@current-change="currentChangeHandle" :current-page="pageIndex" :page-sizes="[10, 20, 50, 100]"
							:page-size="pageSize" :total="totalPage" layout="total, sizes, prev, pager, next, jumper">
						</el-pagination>
					</el-col>
				</el-row>
			</el-card>
		</div>
		<!-- 弹窗, 新增节点 -->
		<add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList()"></add-or-update>
	</div>
</template>
<script>
import AddOrUpdate from './projectchange-add-or-update.vue'
import $common from "@/utils/common.js"
export default {
	data() {
		return {
			disabledBatchDelete: true,
			pageSpan: 12,
			form: {},
			changeProject: '',
			changeContext: '',
			addOrUpdateVisible: false,
			dataList: [],
			//当前页码
			pageIndex: 1,
			// 显示序号
			pageIndex2: 1,
			//当前记录数
			pageSize: 20,
			//总页数
			totalPage: 0,
			tableSelVal: []
		};
	},
	components: {
		AddOrUpdate,
	},
	mounted() {
		this.getDataList();
	},
	methods: {
		// 新增检验项目
		addOrUpdateHandle(id) {
			this.addOrUpdateVisible = true
			this.$nextTick(() => {
				this.$refs.addOrUpdate.init(id)
			})
		},
		siteContentViewHeight() {
			var height = this.$store.state.common.documentClientHeight - 260;
			return { height: height + 'px' }
		},
		handleSelectionChange(val) {
			this.tableSelVal = val;
			if (val.length > 0) {
				this.disabledBatchDelete = false;
			} else {
				this.disabledBatchDelete = true;
			}
		},
		tableRowClassName({ row, rowIndex }) {
			let color = ''
			for (let item of this.tableSelVal) {
				if (item.id === row.id) color = 'table-SelectedRow-bgcolor'
			}
			return color
		},
		currentChangeHandle(val) {
			this.pageIndex = val
			this.getDataList()
		},
		// 每页数
		sizeChangeHandle(val) {
			this.pageSize = val
			this.pageIndex = 1
			this.getDataList()
		},
		getDataList() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/projectchange/list",
				method: "get",
				params: {
					workNo: $common.getItem("workNo"),
					changeProject: this.changeProject,
					changeContext: this.changeContext,
					limit: this.pageSize,
					page: this.pageIndex
				},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.dataList = data.body.records;
					this.totalPage = data.body.totalCount;
					this.pageIndex2 = this.pageIndex
				}
			});
		},
		deleteBatch() {
			this.$confirm(`确定对选中的数据进行删除操作？`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let records = this.tableSelVal.map((item) => item.recNo);
				this.$http({
					url: this.$store.state.httpUrl + "/business/projectchange/deleteBatch",
					method: 'post',
					data: this.$http.adornData({
						records: records
					})
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					}
				});
			});
		},
		downloadHandle() {
			const rLoading = this.openLoading();
			this.$http({
				url: this.$store.state.httpUrl + "/business/projectchange/download",
				method: 'get',
				params: this.$http.adornParams({
					workNo: $common.getItem("workNo"),
					changeProject: this.changeProject,
					changeContext: this.changeContext,
				})
			}).then(({ data }) => {
				rLoading.close();
				if (data && data.resultCode === 200) {
					if (this.$store.state.showTestFlag) {
						window.open(data.body)
					} else {
						this.global.downLoadFile(data.body, '工程变更信息表.xlsx');
					}
				} else {
				}
			})
		},
		resetFormData() {
			this.changeProject = "";
			this.changeContext = "";
			this.pageIndex = 1;
			this.getDataList();
		},
		selGetDataList() {
			this.pageIndex = 1;
			this.getDataList();
		},
		tableRowClassName({ row, rowIndex }) {
			return $common.tableRowClassName({ row, rowIndex });
		},
		selectRowBackground({ row }) {
			const checkIdList = this.tableSelVal.map((item) => item.recNo);
			if (checkIdList.includes(row.recNo)) {
				return {
					backgroundColor: "#fae8cb",
				};
			}
		},
	}
};
</script>
<style lang="scss" scoped="scoped">
.selItemText {
	font-size: 14px;
	color: #1c1c1c;
	display: inline-block;
	width: 80px;
}

.my-pagination {
	margin-right: 20px !important;
}

// 设置表头样式
::v-deep(.el-table th.el-table__cell) {
	// background-color: #d3e2f4 !important;
	font-size: 15px;
	padding: 2px 0;
}

::v-deep(.selSearchInput .el-input__inner) {
	width: 150px !important;
	height: 30px !important;
}

::v-deep(.selFormItem .el-form-item__label) {
	font-size: smaller;
}
</style>
